<template>
  <div class="setting_form !h-full">  <!-- this will be later changed to parent -->
    <b-modal :modal-class="{'activeTour' : getGuidedTour.step5}" centered modal-class="save_brand_model"
           id="changeEmailModal" hide-footer
           hide-header>
    <div class="w-full h-full">
      <div class="flex flex-col justify-evenly items-center">
          <div class="flex flex-col justify-evenly items-center w-full pt-[1.714rem] px-[1.714rem] pb-[2.85rem] gap-4">
            <div class="flex flex-col justify-center items-center w-full">
              <svg
                width="40"
                height="30"
                viewBox="0 0 40 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.51428 2H34.4857C36.4775 2 38.1071 3.4625 38.1071 5.25V24.75C38.1071 26.5375 36.4775 28 34.4857 28H5.51428C3.5225 28 1.89285 26.5375 1.89285 24.75V5.25C1.89285 3.4625 3.5225 2 5.51428 2Z"
                  stroke="#2560D7"
                  stroke-width="2.97143"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M38.1071 5.25L20 16.625L1.89285 5.25"
                  stroke="#2560D7"
                  stroke-width="2.97143"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p
                class="text-[#3C4549] font-poppins pt-[1.188rem] text-[1.14rem] font-semibold leading-5"
              >
                Change Email
              </p>
              <p class="text-gray-500 pt-1 pb-2 text-[14px] font-normal font-poppins">
                Please enter details to update your email address.
              </p>
            </div>
            <div class="flex flex-col justify-center items-center w-full">
              <FloatingLabelInput
                class="mb-3 w-full"
                id="new_email"
                v-model="newEmail"
                customClass="h-[56px] bg-[#F4F6FA]"
                type="text"
                label="New Email Address"
              >
                <template v-slot:error_message>
                  <InputFieldMessage
                    v-if="validations.newEmail"
                    :message="messages.newEmail"
                  ></InputFieldMessage>
                </template>
              </FloatingLabelInput>

              <FloatingLabelInput
                class="mb-3 w-full"
                id="newConfirmEmail"
                v-model="newConfirmEmail"
                customClass="h-[56px] bg-[#F4F6FA]"
                type="text"
                label="Confirm New Email Address"
              >
                <template v-slot:error_message>
                  <InputFieldMessage
                    v-if="validations.newConfirmEmail"
                    :message="messages.newConfirmEmail"
                  ></InputFieldMessage>
                </template>
              </FloatingLabelInput>
            </div>
          </div>
          <div class="flex flex-row justify-center items-center pb-[2.85rem]">
            <Button
              @click="closeEmailModal()"
              id="close-email-modal-button"
              type="button"
              customClass="px-[2.28rem] py-[1.14rem] !mr-[12px] !rounded-[8px] text-[14px] text-sm leading-[0.875rem] border-[1.5px] border-gray-700"
            >
              <template v-slot:label>Close</template>
            </Button>
            <Button
              :disabledProp="eitherUpdateEmailAddress"
              @click.prevent="updateEmail()"
              id="change-email-detail-button"
              type="button"
              class="text-white bg-blue-600 hover:bg-blue-700"
              customClass="px-[2.28rem] py-[1.14rem] !rounded-[8px] text-[14px] leading-[0.875rem] text-white"
            >
              <template v-slot:label v-if="!Loaders.emailUpdateLoader"
                >Update</template
              >
              <template v-if="Loaders.emailUpdateLoader" v-slot:loader>
                <Loader></Loader>
              </template>
            </Button>

          </div>
        </div>
    </div>
  </b-modal>
    <div class=" w-full bg-white rounded-[8px]">
    <div class="flex flex-col justify-start items-center h-full w-full" >
      <div class="text font-bold text-center !text-[1.125rem] !text-[#3C4549] w-full border-b py-[1rem] flex justify-center items-center h-[4.188rem]">
        Profile Settings
      </div>
      <!-- the heading part -->
      <div class="flex flex-col justify-center items-center h-full w-full px-3" >
        <div class="py-28 "></div>

        <div class=" main_center_div flex flex-col justify-center items-center md:!flex-row md:w-4/5 w-full ">
        <div id="image-div" class="flex items-center justify-center md:py-6" style="flex:0.5; " >
          <div class="input_field w-full h-full flex flex-col justify-center items-center " >
            <div class="profile_image !w-[10rem] !h-[10rem]  rounded-full"
              :style="{ background: 'url(' + backgroundImage(getProfile) + ')' }">
              <div class="input_file" v-if="!image_loader">
                <input
                  @change="uploadProfilePicture"
                  type="file"
                  class="profile_input"
                  id="profile_input"
                  data-cy="p-image"
                />
                <label for="profile_input">
                  <i
                    v-tooltip.top-center="'Change Image'"
                    class="far fa-pencil"
                  ></i>
                </label>

                <!--to remove image-->
                <input
                  type="text"
                  class="profile_input"
                  v-if="getProfile.profile_image"
                  @click="removeProfilePicture"
                  id="remove_image_input"
                />
                <label
                  v-if="getProfile.profile_image"
                  for="remove_image_input"
                  class="remove_image"
                >
                  <i
                    v-tooltip.top-center="'Remove Image'"
                    class="far fa-close"
                  ></i>
                </label>
              </div>
              <div class="input_file bg_opacity" v-else>
                <clip-loader :size="size" :color="color"></clip-loader>
              </div>
            </div>
          </div>
        </div>
        <div id="basic-detail "  style="flex:1">
          <div class="w-100 px-9 border-r-[2px] border-r-[#F2F3F8] border-l-[2px] border-l-[#F2F3F8]">
            <FloatingLabelInput
              class="mb-3"
              id="first_name"
              v-model="first_name"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="text"
              label="First Name"
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="validations.first_name"
                  :message="messages.first_name"
                ></InputFieldMessage>
              </template>
            </FloatingLabelInput>

            <FloatingLabelInput
              class="mb-3"
              id="last_name"
              v-model="last_name"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="text"
              label="Last Name"
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="validations.last_name"
                  :message="messages.last_name"
                ></InputFieldMessage>
              </template>
            </FloatingLabelInput>

            <FloatingLabelInput
              class="mb-3"
              id="email"
              disabled="true"
              v-model="email"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="text"
              label="Email Address"
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="validations.email"
                  :message="messages.email"
                ></InputFieldMessage>
              </template>
              <template v-slot:right_icon>
                <svg
                  @click="changeEmail()"
                  class="pointer-events-auto"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.8969 1.70398C13.3477 1.25323 13.959 1 14.5965 1C15.2339 1 15.8453 1.25323 16.296 1.70398C16.7468 2.15473 17 2.76608 17 3.40354C17 4.041 16.7468 4.65234 16.296 5.1031L5.53215 15.867L1 17L2.13304 12.4678L12.8969 1.70398Z"
                    stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </template>
            </FloatingLabelInput>
            <Button
              :disabledProp="eitherUpdate"
              @click.prevent="saveProfile()"
              id="save-basic-detail-button"
              type="button"
              class="text-white bg-blue-600 hover:bg-blue-700"
              customClass="px-[2.28rem] py-[1.14rem] !rounded-[8px] text-[14px] leading-[0.875rem] text-white"
            >
              <template v-slot:label v-if="!Loaders.basicProfileLoader"
                >Save</template
              >
              <template v-if="Loaders.basicProfileLoader" v-slot:loader>
                <Loader></Loader>
              </template>
            </Button>
          </div>
        </div>
        <div id="password-detail " class=" mt-2 md:mt-0   " style="flex:1">
          <div class="box_content px-9 ">
            <FloatingLabelInput
              class="mb-3"
              id="current_password"
              v-model="password.current_password"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="password"
              label="Current Password "
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="validations.current_password"
                  :message="messages.current_password"
                ></InputFieldMessage>
              </template>
            </FloatingLabelInput>

            <FloatingLabelInput
              class="mb-3"
              id="new_password"
              v-model="password.new_password"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="password"
              label="New Password "
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="validations.new_password"
                  :message="messages.new_password"
                ></InputFieldMessage>
              </template>
            </FloatingLabelInput>

            <FloatingLabelInput
              class="mb-3"
              id="confirm_new_password"
              v-model="password.confirm_new_password"
              customClass="h-[56px] bg-[#F4F6FA]"
              type="password"
              label="Confirm Password"
            >
              <template v-slot:error_message>
                <InputFieldMessage
                  v-if="validations.confirm_new_password"
                  :message="messages.confirm_new_password"
                ></InputFieldMessage>
              </template>
            </FloatingLabelInput>

            <Button
              :disabledProp="eitherupdatePassword"
              @click.prevent="updatePassword()"
              id="update-password-button"
              type="button"
              class="text-white bg-blue-600 hover:bg-blue-700"
              customClass="px-[2.28rem] py-[1.14rem] text-[14px] leading-[0.875rem] !rounded-[8px] text-white"
            >
              <template v-slot:label v-if="!Loaders.updatePasswordLoader"
                >Update</template
              >

              <template v-if="Loaders.updatePasswordLoader" v-slot:loader>
                <Loader></Loader>
              </template>
            </Button>
          </div>
        </div>
      </div>
      <div
        class="flex flex-col justify-end items-center w-full !p-8 gap-4 rounded-md !bg-[#F4F6FA] mt-48"
        v-if="getProfile.change_email_token"
      >
        <div class="mt-3 text-center">
          <p>
            Thank you for your recent request to change your email address. We
            have processed your request and sent a confirmation email to the new
            email address provided,
            <strong> {{ getProfile.change_email }}</strong>
          </p>
        </div>
        <div class="flex flex-row justify-center items-center gap-2 w-full">
          <Button
            @click.prevent="cancelChangeEmailRequest()"
            id="cancel-request-button"
            type="button"
            customClass="px-[0.75rem] py-[0.75rem] text-sm leading-[0.875rem] !rounded-[8px] border-[1.5px] border-gray-700"
          >
            <template v-slot:label>Cancel Request</template>
          </Button>
          <Button
            @click.prevent="resendChangeEmailRequest()"
            id="resend-email-button"
            type="button"
            class="text-white bg-blue-600 hover:bg-blue-700"
            customClass="px-[0.75rem] py-[0.75rem] text-sm leading-[0.875rem] text-white !rounded-[8px]"
          >
            <template v-slot:label>Resend Email</template>
          </Button>

        </div>
      </div>
      </div>


    </div>
  </div>
  </div>
</template>

<script>
import {
  removeProfilePictureURL,
  saveProfileURL,
  updatePasswordURL,
  uploadProfilePictureURL,
} from "@/config/config.js";
import { mapActions, mapGetters } from "vuex";
import {
  cancelChangeEmailURL,
  changeEmailURL,
  resendChangeEmailURL,
} from "@/config/urls/user/user.js";
import { DARK_PURPLE_LOADER_COLOR } from "@/common/constants";
import http from "@/mixins/http-lib";
import { deleteDomainURL } from "@/config/api";
import Heading from "./Reuseable/Heading.vue";
import Button from "@/ui/ui-kit/v2/Button.vue";
import FloatingLabelInput from "@/ui/ui-kit/v2/FloatingLabelInput.vue";
import validaitonsWithMessageMixin from "@/mixins/validations/validations-with-message.js";
import InputFieldMessage from "@/ui/ui-kit/v2/InputFieldMessage.vue";

export default {
  components: {
    Heading,
    Button,
    FloatingLabelInput,
    InputFieldMessage,
    Loader: () => import("@/ui/ui-kit/v2/Loader.vue"),
  },
  mixins: [validaitonsWithMessageMixin],
  data: function () {
    return {
      first_name: "",
      last_name: "",
      email: "",
      newEmail: "",
      newConfirmEmail: "",
      default: "Heading",
      Loaders: {
        basicProfileLoader: false,
        updatePasswordLoader: false,
        emailUpdateLoader: false,
      },
      size: "17px",
      color: DARK_PURPLE_LOADER_COLOR,
      notAvailableError: false,
      notAvailableEmail: "",

      password: {
        new_password: "",
        confirm_new_password: "",
        current_password: "",
      },
      messages: {
        current_password: "Please enter your current password.",
        new_password: "Please enter your new password.",
        confirm_new_password: "Please confirm your new password.",
        first_name: "Please enter your first name",
        last_name: "Please enter your last name",
        email: "Please enter your Email",
        newEmail: "Please enter your new email",
        newConfirmEmail: "please enter your confirmation email",
      },
      validations: {
        current_password: false,
        new_password: false,
        confirm_new_password: false,
        first_name: false,
        last_name: false,
        email: false,
        newEmail: false,
        newConfirmEmail: false,
      },
      image_loader: false,
    };
  },

  created() {
    this.getSiteDetails;
    this.email = this.getProfile.email;
    this.first_name = this.getProfile.first_name;
    this.last_name = this.getProfile.last_name;
    // this.new_password = ''; // to start the computed
  },
  computed: {
    ...mapGetters(["getProfile", "getUserLoader"]),
    eitherUpdate() {
      return !(
        (this.first_name !== this.getProfile.first_name ||
          this.last_name !== this.getProfile.last_name) &&
        this.first_name.trim().length > 0 &&
        this.last_name.trim().length > 0
      );
    },
    eitherUpdateEmailAddress() {
      return  this.validations.newEmail ||
        this.validations.newConfirmEmail ||
        (this.newEmail.trim().length === 0 && this.newConfirmEmail.trim().length === 0);
    },
    eitherupdatePassword() {
      // if anyone have validation true or all is empty , the next one is to disable at render time
      return (
        this.validations.current_password ||
        this.validations.new_password ||
        this.validations.confirm_new_password ||
        (this.password.new_password.trim().length == 0 &&
          this.password.current_password.trim().length == 0 &&
          this.password.confirm_new_password.trim().length == 0)
      );
    },
  },
  methods: {
    ...mapActions(["setProfileImage", "setChangeEmailLoader"]),
    closeEmailModal(){
      this.newConfirmEmail='';
      this.newEmail='';
      this.validations.newConfirmEmail=false;
      this.validations.newEmail=false

      this.$bvModal.hide('changeEmailModal');
    },
    saveProfile() {
      if (!this.validateBasicProfileRules()) return;
      this.Loaders.basicProfileLoader = true;
      http
        .post(saveProfileURL, {
          first_name: this.first_name,
          last_name: this.last_name,
        })
        .then(
          (response) => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, "error");
            } else {
              if (response.data.status) {
                this.getProfile.first_name = this.first_name;
                this.getProfile.last_name = this.last_name;
                this.alertMessage(response.data.message, "success");
              } else {
                this.alertMessage(response.data.message, "error");
              }
            }
          },
          (response) => {}
        )
        .finally(() =>
          setTimeout(() => {
            this.Loaders.basicProfileLoader = false;
          }, 500)
        );
    },

    uploadProfilePicture(e) {
      this.image_loader = true;
      console.debug("picture upload");
      e.preventDefault();
      let files = e.target.files || e.dataTransfer.files;
      let formData = new FormData();
      formData.append("profile_picture", files[0]);

      http
        .post(uploadProfilePictureURL, formData)
        .then(
          (response) => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, "error");
            } else {
              if (response.data.status) {
                this.setProfileImage(response.data.url);
                this.alertMessage(response.data.message, "success");
              } else {
                this.alertMessage(response.data.message, "error");
              }
            }
          },
          (response) => {}
        )
        .finally(() => (this.image_loader = false));
    },
    removeProfilePicture(e) {
      var self = this;
      swal(
        {
          title: "Are you sure ?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, Cancel!",
          closeOnConfirm: true,
          customClass: "sweetAlert_box",
          closeOnCancel: true,
          animation: false,
        },
        function (isConfirm) {
          if (isConfirm) {
            http.post(removeProfilePictureURL, {}).then((response) => {
              if (
                response.data.tokenError &&
                response.data.tokenError == true
              ) {
                self.alertMessage(response.data.message, "error");
              } else {
                if (response.data.status) {
                  self.setProfileImage(response.data.url);
                  self.alertMessage(response.data.message, "success");
                } else {
                  self.alertMessage(response.data.message, "error");
                }
              }
            });
          } else {
            swal();
          }
        }
      );
    },

    changeEmail() {
      this.newEmail = "";
      this.newConfirmEmail = "";
      this.notAvailableError = false;
      this.notAvailableEmail = "";
      this.$bvModal.show("changeEmailModal");
    },

    updateEmail() {
      // change message text here
      if (!this.validateEmailRules()) return;

      this.notAvailableError = false;
      this.notAvailableEmail = "";
      this.setChangeEmailLoader(true);
      this.Loaders.emailUpdateLoader = true;

      http
        .post(changeEmailURL, {
          email: this.newEmail,
        })
        .then(
          (response) => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, "error");
            } else {
              if (response.data.status) {
                this.alertMessage(response.data.message, "success");
                this.getUserProfile();
                // $('#changeEmailModal').modal('hide')
                this.$bvModal.hide("changeEmailModal");
              } else {
                if (response.data.notAvailable) {
                  this.notAvailableError = true;
                  this.notAvailableEmail = this.newEmail;
                } else {
                  this.alertMessage(response.data.message, "error");
                }
              }
              this.setChangeEmailLoader(false);
            }
          },
          (response) => {
            this.setChangeEmailLoader(false);
          }
        )
        .finally(() => (this.Loaders.emailUpdateLoader = false));
    },

    resendChangeEmailRequest() {
      http.post(resendChangeEmailURL).then(
        (response) => {
          if (response.data.tokenError && response.data.tokenError == true) {
            this.alertMessage(response.data.message, "error");
          } else {
            if (response.data.status) {
              this.alertMessage(response.data.message, "success");
            } else {
              this.alertMessage(response.data.message, "error");
            }
          }
        },
        (response) => {
          this.alertMessage("Operation Failed", "error");
        }
      );
    },
    cancelChangeEmailRequest() {
      http.post(cancelChangeEmailURL).then(
        (response) => {
          if (response.data.tokenError && response.data.tokenError == true) {
            this.alertMessage(response.data.message, "error");
          } else {
            if (response.data.status) {
              this.getUserProfile();
              this.alertMessage(response.data.message, "success");
            } else {
              this.alertMessage(response.data.message, "error");
            }
            custom;
          }
        },
        (response) => {
          this.alertMessage("Operation Failed", "error");
        }
      );
    },
    validateBasicProfileRules() {
      /* the first parameter is the value , the second is name of the field , the third is list of funcion names as array and fourth is validation and message object key */
      this.validateField(
        this.first_name,
        "First Name",
        ["validateRequired", "validateMinLength", "validateMaxLength"],
        ["first_name", "first_name"]
      );
      this.validateField(
        this.last_name,
        "Last Name",
        ["validateRequired"],
        ["last_name", "last_name"]
      );

      // if any of the validation is true then return false so that backend request can be prevented
      return this.validations.first_name || this.validations.last_name
        ? false
        : true;
    },

    validateEmailRules() {
      /* the first parameter is the value , the second is name of the field , the third is list of funcion names as array and fourth is validation and message object key */
      this.validateField(
        this.newEmail,
        "New Email",
        [
          "validateRequired",
          "validateMinLength",
          "validateEmail",
          "validateNotSame",
        ],
        ["newEmail", "newEmail"],
        this.newEmail, // for rules that have more than 2 arguments we have extra arguments , it is like that new email cannot be like the current email
        this.email,
        "New Email",
        "Current Email"
      );
      this.validateField(
        this.newConfirmEmail,
        "Confirmation Email",
        [
          "validateRequired",
          "validateMinLength",
          "validateEmail",
          "validateSame",
        ],
        ["newConfirmEmail", "newConfirmEmail"],
        this.newConfirmEmail, // for rules that have more than 2 arguments we have extra arguments , it is like that new email must be like the current email
        this.newEmail,
        "Confirmation Email",
        "NewEmail"
      );

      // if any of the validation is true then return false so that backend request can be prevented
      return this.validations.new_email || this.validations.newConfirmEmail
        ? false
        : true;
    },

    validatePassowordRules() {
      /* the first parameter is the value , the second is name of the field , the third is list of funcion names as array and fourth is validation and message object key */
      this.validateField(
        this.password.current_password,
        "Current Password",
        ["validateRequired", "validatePassword"],
        ["current_password", "current_password"]
      );
      this.validateField(
        this.password.new_password,
        "New Password",
        ["validateRequired", "validatePassword", "validateNotSame"],
        ["new_password", "new_password"],
        this.password.new_password, // for rules that have more than 2 arguments we have extra arguments
        this.password.current_password,
        "New Password",
        "Current Password"
      );

      this.validateField(
        this.password.confirm_new_password,
        "Confirm New Password",
        ["validateRequired", "validatePassword", "validateSame"],
        ["confirm_new_password", "confirm_new_password"],
        this.password.confirm_new_password, // for rules that have more than 2 arguments we have extra arguments
        this.password.new_password,
        "Confirm New Password",
        "New Password"
      );
      // if any of the validation is true then return false so that backend request can be prevented
      return this.validations.current_password ||
        this.validations.new_password ||
        this.validations.confirm_new_password
        ? false
        : true;
    },

    // password detail update method
    updatePassword() {
      if (!this.validatePassowordRules()) return; // this can be ignored as buttons is only enabled when there is no validation error
      this.Loaders.updatePasswordLoader = true;
      http
        .post(updatePasswordURL, {
          new_password: this.password.new_password,
          confirm_new_password: this.password.confirm_new_password,
          current_password: this.password.current_password,
        })
        .then(
          (res) => {
            if (res.data.tokenError && res.data.tokenError == true) {
              this.alertMessage(res.data.message, "error");
            } else {
              if (res.data.status) {
                this.alertMessage(res.data.message, "success");
              } else {
                this.alertMessage(res.data.message, "error");
              }
            }
          },
          (err) => {
            this.alertMessage("Operation Failed", "error");
          }
        )
        .finally(() =>
          setTimeout(() => {
            this.Loaders.updatePasswordLoader = false;
          }, 500)
        );
    },
  },
  watch: {
    newEmail() {
      this.validateEmailRules();
    },
    newConfirmEmail() {
      this.validateEmailRules();
    },
    first_name() {
      this.validateBasicProfileRules();
    },
    last_name() {
      this.validateBasicProfileRules();
    },
    password: {
      handler(newValue, oldValue) {
        this.validatePassowordRules(); // this
      },
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>

#image-div{
  align-self: auto;

@media (min-width: 768px) {
  align-self: stretch;
}
}
.bg_opacity {
  background: rgba(0, 0, 0, 0.51) !important;
}

.heading_shadow {
 border-bottom: 1px solid #D2D5DF;

}

:deep(.custom-rounded-md) {
  border-radius: 1rem;
}
.setting_form {
  box-shadow: 0px 3px 10px 0px rgba(211, 217, 236, 0.2);
  border-radius: 8px;
  background: var(--White, #fff);
}

.main_center_div{

@media (max-width: 768px) {
    min-width: 14.28rem;
    max-width: 28.57rem;

    }
}
</style>
