<template>
  <div class="flex items-center justify-center font-bold text-gray-700 text-base  leading-4  rounded-md bg-white  text-center min-h-[2rem] align-middle w-full font-poppins text-[1rem] ">
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

